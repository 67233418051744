import { useState, useEffect } from 'react'
import { Form, useActionData, useLoaderData, useLocation, useNavigation, useSubmit } from '@remix-run/react'
import { User } from "~/types"
import { ArrowLeft } from "lucide-react"
import { Card, CardContent, CardHeader, CardTitle } from "@components/ui/card"
import { Button } from "@components/ui/button"
import { Input } from "@components/ui/input"
import { Label } from "@components/ui/label"
import { ActionFunction, json, LoaderFunction, redirect, TypedResponse } from "@remix-run/node";
import { getDeviceInfo, requestOtp, verifyOtp } from "@services/auth.server";
import { commitSession, getSession } from "@utils/session.server";

interface ActionData {
    success: boolean;
    message?: string;
    user?: User;
    intent?: string;
}

interface LoaderData {
    success: boolean;
    message?: string;
    domain?: string;
}

export const loader: LoaderFunction = async ({ request }): Promise<LoaderData> => {

    const url = new URL(request.url);
    const domain = url.hostname;

    return {
        success: true,
        domain,
    }
}

export const action: ActionFunction = async ({
    request
}): Promise<TypedResponse<ActionData>> => {
    try {
        const formData = await request.formData();
        const intent = formData.get('intent') as string;
        const phoneNumber = formData.get('phoneNumber') as string;
        const otp = formData.get('otp') as string;

        // Get the returnTo URL from the form data
        const returnTo = formData.get('returnTo') as string;

        if (!intent) {
            return json<ActionData>(
                {
                    success: false,
                    message: 'Intent is required'
                },
                { status: 400 }
            );
        }

        const deviceInfo = getDeviceInfo();

        switch (intent) {
            case 'getOtp': {
                const payload = {
                    app: 'seller_app',
                    mobileNumber: phoneNumber,
                    password: '',
                    admin: false,
                    deviceInfo,
                };

                await requestOtp(payload);

                return json<ActionData>({
                    success: true,
                    message: 'OTP sent successfully',
                    intent: 'getOtp'
                });
            }

            case 'verifyOtp': {
                if (!phoneNumber || !otp) {
                    return json<ActionData>(
                        {
                            success: false,
                            message: 'Phone number and OTP are required',
                            intent: 'verifyOtp',
                        },
                        { status: 400 }
                    );
                }

                const verifyResponse = await verifyOtp(phoneNumber, otp);

                if (!verifyResponse.data.access_token || !verifyResponse.data.refresh_token) {
                    throw new Error('Invalid authentication response');
                }

                const session = await getSession(request.headers.get('Cookie'));
                session.set('access_token', verifyResponse.data.access_token);
                session.set('refresh_token', verifyResponse.data.refresh_token);

                const headers = new Headers();
                const cookieString = await commitSession(session);
                headers.set('Set-Cookie', cookieString);

                // Redirect to the return URL if it exists, otherwise to dashboard
                const redirectTo = returnTo ? decodeURIComponent(returnTo) : '/home/dashboard';
                return redirect(redirectTo, {
                    headers
                });
            }

            case 'clearActionData': {
                return json<ActionData>({
                    success: true,
                    message: '',
                    intent: 'clearActionData'
                });
            }

            default: {
                return json<ActionData>(
                    {
                        success: false,
                        message: 'Invalid intent'
                    },
                    { status: 400 }
                );
            }
        }
    } catch (error) {
        console.error('Authentication error:', error);
        return json<ActionData>(
            {
                success: false,
                message: error instanceof Error ? error.message : 'Authentication failed'
            },
            { status: 500 }
        );
    }
};

export default function Login() {
    const loader = useLoaderData<LoaderData>();

    const [step, setStep] = useState('phone')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [otp, setOtp] = useState('')
    const [delay, setDelay] = useState(59)
    const [isMnet, setMnet] = useState(false);


    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const returnTo = searchParams.get('returnTo');

    const actionData = useActionData()
    const navigation = useNavigation()
    const submit = useSubmit()

    useEffect(() => {
        console.log("domain...", loader?.domain);
        if (loader?.domain && loader?.domain === "farmersmandi.in") {
            setMnet(true);
        }
    }, [loader?.domain])



    useEffect(() => {
        let timer
        if (step === 'otp' && delay > 0) {
            timer = setTimeout(() => setDelay(delay - 1), 1000)
        }
        console.log("zazazaza Login useEffect 2: ", step, delay)
        return () => clearTimeout(timer)
    }, [step, delay])

    const handleResendOtp = () => {
        setOtp('')
        setDelay(59)
        setStep('otp')
    }

    useEffect(() => {
        if (actionData?.success && actionData.intent === 'getOtp') {
            setStep('otp')
            setDelay(59)
            console.log("zazazaza Login useEffect 1: ", actionData)
        }
    }, [actionData])

    const handleBack = () => {
        setStep('phone')
        setOtp('')
        submit({ intent: 'clearActionData' }, { method: 'post', replace: true })
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-background p-4 relative overflow-hidden">
            {/* Grid Background with Fade */}
            <div className="absolute inset-0 opacity-10">
                <div className="absolute inset-0 bg-gradient-to-b from-transparent via-background to-background z-10" />
                <div className="grid grid-cols-12 gap-4 h-full w-full">
                    {[...Array(144)].map((_, i) => (
                        <div key={i} className="border-[0.5px] border-fm-green/20 h-full" />
                    ))}
                </div>
            </div>

            <div className="w-full max-w-6xl relative z-20">
                {/* Main Login Section */}
                <div className={`grid ${isMnet ? "md:grid-cols-2 gap-8" : ""} min-h-[80vh]`}>
                    {/* Left Side - Features */}
                    {isMnet && <Card
                        className="bg-gradient-to-br from-fm-green/5 to-transparent backdrop-blur-sm border-fm-green/20 flex flex-col">
                        <CardHeader className="flex-none">
                            <div className="flex items-center gap-4 mb-6">
                                <div>
                                    <div className="text-xl font-semibold text-fm-green">farmersMandi</div>
                                    <div className="text-xs text-gray-500">by Brih Solutions</div>
                                </div>
                                <div className="h-8 w-[1px] bg-gray-300" />
                                <img src="/mnet-logo.svg" alt="mNet Logo" className="h-8" />
                            </div>
                            <CardTitle className="text-2xl font-medium mb-8">Helping businesses launch their own
                                e-commerce app</CardTitle>
                        </CardHeader>
                        <CardContent className="flex-grow flex flex-col justify-between">
                            <ul className="space-y-6">
                                <li className="flex items-center gap-4">
                                    <div className="bg-fm-green/10 p-3 rounded-full">
                                        <svg className="w-6 h-6 text-fm-green" fill="none" strokeLinecap="round"
                                            strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path d="M5 13l4 4L19 7" />
                                        </svg>
                                    </div>
                                    <span className="text-base">Launch your own app within a week</span>
                                </li>
                                <li className="flex items-center gap-4">
                                    <div className="bg-fm-green/10 p-3 rounded-full">
                                        <svg className="w-6 h-6 text-fm-green" fill="none" strokeLinecap="round"
                                            strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path d="M5 13l4 4L19 7" />
                                        </svg>
                                    </div>
                                    <span className="text-base">Go online and increase your sales</span>
                                </li>
                                <li className="flex items-center gap-4">
                                    <div className="bg-fm-green/10 p-3 rounded-full">
                                        <svg className="w-6 h-6 text-fm-green" fill="none" strokeLinecap="round"
                                            strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path d="M5 13l4 4L19 7" />
                                        </svg>
                                    </div>
                                    <span className="text-base">Build your brand and gain trust</span>
                                </li>
                                <li className="flex items-center gap-4">
                                    <div className="bg-fm-green/10 p-3 rounded-full">
                                        <svg className="w-6 h-6 text-fm-green" fill="none" strokeLinecap="round"
                                            strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24"
                                            stroke="currentColor">
                                            <path d="M5 13l4 4L19 7" />
                                        </svg>
                                    </div>
                                    <span className="text-base">Streamline customer communications</span>
                                </li>
                            </ul>


                            <div className="pt-8 border-t border-gray-200 mt-12">
                                <h3 className="text-base font-medium mb-6">Trusted by businesses across India</h3>
                                <div className="grid grid-cols-4 gap-6">
                                    {[...Array(8)].map((_, index) => (
                                        <div key={index}
                                            className="bg-white p-4 rounded-full w-24 h-24 flex items-center justify-center shadow-sm hover:shadow-md transition-shadow">
                                            <img
                                                src={`/customer${index + 1}.png?height=60&width=60`}
                                                alt={`Customer logo ${index + 1}`}
                                                className="w-16 h-16 object-contain"
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                    }
                    {/* Right Side - Login Form */}
                    <div className="flex items-center justify-center">
                        <Card className="backdrop-blur-sm border-fm-green/20 w-full max-w-md shadow-lg">
                            <CardHeader className="text-center pb-4">
                                <CardTitle className="text-xl font-bold">Login to mNet Business Console</CardTitle>
                                {isMnet && <p className="text-sm text-gray-500 mt-1">Your fruit & vegetable Mandi online</p>}
                            </CardHeader>
                            <CardContent>
                                <Form method="post" className="space-y-6">
                                    {step === 'phone' ? (
                                        <>
                                            <div className="space-y-2">
                                                <Label htmlFor="phoneNumber">Phone Number</Label>
                                                <div className="flex rounded-md shadow-sm">
                                                    <span
                                                        className="inline-flex items-center rounded-l-md border border-r-0 border-input bg-muted px-3 text-sm text-muted-foreground">
                                                        +91
                                                    </span>
                                                    <Input
                                                        type="tel"
                                                        id="phoneNumber"
                                                        name="phoneNumber"
                                                        value={phoneNumber}
                                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                                        maxLength={10}
                                                        required
                                                        className="rounded-l-none"
                                                    />
                                                </div>
                                                <p className="text-sm text-muted-foreground">We'll send you an OTP on
                                                    this number</p>
                                            </div>
                                            <Button
                                                type="submit"
                                                name="intent"
                                                value="getOtp"
                                                disabled={phoneNumber.length < 10 || navigation.state === 'submitting'}
                                                className="w-full bg-fm-green hover:bg-fm-hover"
                                            >
                                                {navigation.state === 'submitting' ? 'Sending...' : 'GET OTP'}
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <input type="hidden" name="phoneNumber" value={phoneNumber} />
                                            <div className="space-y-2">
                                                <div className="flex items-center">
                                                    <Button
                                                        type="button"
                                                        onClick={handleBack}
                                                        variant="ghost"
                                                        size="sm"
                                                        className="mr-2"
                                                    >
                                                        <ArrowLeft size={16} />
                                                    </Button>
                                                    <Label htmlFor="otp">Enter OTP</Label>
                                                </div>
                                                <Input
                                                    type="number"
                                                    id="otp"
                                                    name="otp"
                                                    value={otp}
                                                    onChange={(e) => setOtp(e.target.value)}
                                                    maxLength={6}
                                                    required
                                                />
                                                <p className="text-sm text-muted-foreground">
                                                    Didn't receive the OTP?{' '}
                                                    <Button
                                                        type="button"
                                                        onClick={handleResendOtp}
                                                        disabled={delay > 0 || navigation.state === 'submitting'}
                                                        variant="link"
                                                        className="p-0 h-auto font-normal text-fm-green"
                                                    >
                                                        Resend OTP {delay > 0 && `in ${delay}s`}
                                                    </Button>
                                                </p>
                                            </div>
                                            <Button
                                                type="submit"
                                                name="intent"
                                                value="verifyOtp"
                                                disabled={otp.length < 6 || navigation.state === 'submitting'}
                                                className="w-full bg-fm-green hover:bg-fm-hover"
                                            >
                                                {navigation.state === 'submitting' ? 'Verifying...' : 'CONTINUE'}
                                            </Button>
                                        </>
                                    )}

                                    {returnTo && <input type="hidden" name="returnTo" value={returnTo} />}
                                </Form>

                                {actionData?.message && actionData.intent !== 'clearActionData' && (
                                    <p className={`mt-4 text-sm ${actionData.success ? 'text-green-600' : 'text-destructive'}`}>
                                        {actionData.message}
                                    </p>
                                )}
                            </CardContent>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}
